/* 警告框样式 */

.success-alert {
    z-index: 11111;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    position: relative;
}

.error-alert {
    z-index: 11111;
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    position: relative;
}

.warn-alert {
    z-index: 11111;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    position: relative;
}

.info-alert {
    z-index: 11111;
    /* background-color: rgba(110, 187, 255, .2); */
    background-color: #E6F7FF;
    border: 1px solid #6EBBFF;
    position: relative;
    color: #fff;
}