.resource-home {
    width: 100%;
    background: url('../images//resource.png') no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.resource-home-title {
    padding-top: 10%;
    text-align: center;
    color: #fff;
}

.resource-home-title>div:nth-child(1) {
    font-size: 60px;
    line-height: 70px;
}

.resource-home-title>div:nth-child(2) {
    line-height: 50px;
    font-size: 18px;
}

.resource-home-title>div:nth-child(2)>b {
    display: inline-block;
    height: 15px;
    vertical-align: middle;
    width: 2px;
    background-color: #fff;
    margin: 0 60px;
}

.resource-home-model {
    margin-top: 2%;
    text-align: center
}

.resource-home-model .resource-model-line>div {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    cursor: pointer;
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    height: 190px;
    border: 1px solid #fff;
}

/* .resource-home-model .resource-model-line>div:last-child {
    border-right: none
} */

.resource-home-model .resource-model-line>div:hover {
    background-color: #30d7c0;
}

/* .resource-home-model .resource-model-line>div:nth-child(5) {
    border-right: 0;
} */

.resource-home-model .resource-model-line>div>img {
    vertical-align: middle;
    margin: 25px 0;
}

.resource-home-model .resource-model-line>div>.name {
    line-height: 50px;
    font-size: 20px;
    padding: 0 20px;
    font-weight: bold;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.resource-home-model .resource-model-line>div>div {
    line-height: 40px;
}

.resource-home-model .resource-model-line>div>div>span {
    display: inline-block;
    width: 50%;
}

.resource-home-model .resource-model-line-1>div {
    border-bottom: 0;
}

.resource-home-model .resource-model-line>div {
    border-right: 0;
}

.resource-home-model .resource-model-line>div:first-child {
    border-left: 0;
}

.click-href {
    cursor: pointer;
    color: #333;
}

.click-href:hover {
    color: #266cff;
}

.table-search {
    width: 200px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.table-search>button {
    margin: 10px 10px;
}

.table-operation>div {
    display: inline-block;
    position: relative;
}

.table-operation>div>span {
    cursor: pointer;
}

.table-operation>div>span>b {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #266cff;
    margin: 0 2px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.table-operation>div>div {
    position: absolute;
    display: none;
    z-index: 1;
    left: -15px;
    top: 22px;
    width: 70px;
    background-color: #fff;
    border: 1px solid #eee;
}

.table-operation>div:hover div {
    display: block;
}

.platform-pop-up {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.import-tool-box {
    width: 500px;
    background-color: #fff;
    min-height: 100px;
    border-radius: 5px;
}

.import-tool-box>.title {
    line-height: 40px;
    padding: 0 20px;
    font-size: 15px;
    font-weight: bold;
    background-color: #efefef;
    border-radius: 5px 5px 0 0;
}

.import-tool-box>.content {
    padding: 0px 20px;
}

.import-tool-box>.bottom {
    text-align: center;
    border-top: 1px solid #ddd;
    padding: 15px 0;
}

.import-tool-box>.content>p {
    color: #F85302;
    margin-top: 10px;
}

.import-tool-box>.content>.tips {
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: right;
}

.import-tool-box>.bottom>button {
    margin: 0 20px;
}

.admin-table .overflow {
    width: 100%;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.admin-table .selectRow {
    background: rgba(38, 108, 255, .1) !important;
}