@media screen and (max-width: 1600px) {
    .platform-header,
    .events-process {
        min-width: 1200px;
    }
    .content-container {
        width: 1200px;
    }
    body {
        min-width: 1200px;
    }
}

@media screen and (min-width: 1600px) {
    .platform-header,
    .events-process {
        min-width: 1400px;
    }
    .content-container {
        width: 1400px;
    }
    body {
        min-width: 1400px;
    }
}
