.platform-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    z-index: 2;
}

.platform-header>.logo {
    position: absolute;
    left: 0px;
    top: 0px;
    margin-left: 20px;
}

.platform-header>.logo>img {
    height: 45px;
    vertical-align: middle;
}

.platform-header>.nav {
    text-align: center;
    width: 100%;
}

.platform-header>.nav>a {
    font-size: 18px;
    color: #fff;
    margin: 0 15px;
    position: relative;
    display: inline-block;
}

.platform-header>.nav>a>b {
    display: inline-block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 2px;
    left: 0px;
}

.platform-header>.nav>.active>b {
    background-color: #30d7c0;
}

.platform-header>.login-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-right: 60px;
}

.has-login {
    position: relative;
    cursor: pointer;
}

.has-login:hover .drop-down {
    display: block;
}

.has-login>.drop-down {
    position: absolute;
    top: 50px;
    left: -30px;
    width: 100px;
    display: none;
    line-height: 30px;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0 0 3px 0 #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.has-login>.drop-down>span {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.has-login>.drop-down>span:hover {
    color: #266cff;
}

.platform-header>.login-btn>.not-login>span {
    display: inline-block;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
}

.platform-header>.login-btn>.not-login>b {
    display: inline-block;
    height: 16px;
    width: 2px;
    background-color: #fff;
    margin: 0 15px;
    vertical-align: middle;
}

.platform-footer {
    height: 40px;
    line-height: 40px;
    background-color: #000000;
    text-align: center;
    color: #fff;
    font-size: 13px;
}

.platform-footer>a {
    color: #fff;
    margin-right: 15px;
    font-size: 13px;
}

.platform-footer>a>img {
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
}

.platform-footer>a:hover {
    color: #266cff;
}

.platform-home>.banner {
    background: url('../images/home-banner.png') no-repeat;
    height: 300px;
    width: 100%;
    background-size: auto 100%;
    background-position: center;
}

.platform-home>.banner>.temporary-login {
    width: 500px;
    margin: 0 auto;
    padding-top: 100px;
}

.platform-home>.banner>.temporary-login>.content {
    background-color: #fff;
    padding: 30px;
}

.platform-home>.banner>.temporary-login>.content>button {
    margin-top: 20px;
}

.platform-home-hot-events {
    padding: 40px 0px;
    width: 100%;
}

.platform-home-hot-events>p,
.events-process>p {
    font-size: 26px;
    text-align: center;
}

.events-process {
    background-color: #F5F5F5;
}

.content-container {
    margin: 0 auto;
}

.content-container>div>span {
    cursor: pointer;
}

.content-container>div>span:hover {
    color: #266cff;
}

.single-hot-events {
    display: inline-block;
    position: relative;
    width: 22%;
    margin: 0 2%;
    border: 1px solid #E9EBEF;
    margin-top: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.single-hot-events>img {
    position: absolute;
    top: 0px;
    left: 0px;
}

.single-hot-events:nth-child(4n-3) {
    margin-left: 0px;
}

.single-hot-events:nth-child(4n) {
    margin-right: 0px;
}

.single-hot-events>div:nth-child(1) {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #E9EBEF;
    text-align: center;
    padding: 30px 0;
}

.single-hot-events>div:nth-child(2) {
    padding: 15px 10%;
    padding-top: 10px;
}

.single-hot-events>div:nth-child(2)>.name {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    line-height: 50px;
    color: #333;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.single-hot-events>div:nth-child(2)>.name:hover {
    color: #266cff;
}

.single-hot-events>div:nth-child(2)>.intro {
    font-size: 14px;
    line-height: 20px;
    color: #666;
    height: 40px;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.single-hot-events>div:nth-child(3) {
    background-color: #E9EBEF;
    padding: 0px 10%;
}

.single-hot-events>div:nth-child(3)>div,
.single-hot-events>div:nth-child(3)>span,
.single-hot-events>div:nth-child(3)>div>span {
    display: inline-block;
    vertical-align: middle;
}

.single-hot-events>div:nth-child(3)>div {
    padding: 15px 0;
    width: 80%;
}

.single-hot-events>div:nth-child(3)>div>span {
    width: 100%;
}

.single-hot-events>div:nth-child(3)>div>span:nth-child(1) {
    font-size: 12px;
    color: #666;
    line-height: 20px;
}

.single-hot-events>div:nth-child(3)>div>span:nth-child(2) {
    font-size: 18px;
    line-height: 30px;
    color: #266cff;
}

.single-hot-events>div:nth-child(3)>span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    background-color: #30d7c0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.events-process {
    position: relative;
}

.events-process>p {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
}

.events-process>div {
    height: 440px;
    width: 100%;
    background: url('../images//flow-path.png') no-repeat;
    background-size: auto 100%;
    background-position: center;
}

.error-message {
    display: block;
    color: #FC7057;
    margin-left: 83px;
    line-height: 30px;
    border: 0 !important;
    word-break: break-all;
}