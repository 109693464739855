.ctf-resource-list {
  position: relative;
  padding-bottom: 70px;
}

.ctf-resource-list > .banner {
  background: url("../images/activity-banner.png") no-repeat;
  height: 305px;
  width: 100%;
  background-size: auto 100%;
  background-position: center center;
}

.ctf-resource-search {
  position: absolute;
  top: 270px;
  width: 100%;
}

.ctf-resource-search > div {
  width: 60%;
  margin: 0 auto;
}

.ctf-resource-search > div.top {
  height: 80px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 5px 0 #ddd;
}

.ctf-resource-search > div.bottom {
  line-height: 40px;
  font-size: 14px;
  color: #999;
}

.ctf-resource-search > .top > .search-input {
  width: calc(100% - 80px);
}

.ctf-resource-search > .top > .search-input > input {
  width: 100%;
  height: 80px;
  padding: 0 20px;
  font-size: 18px;
}

.ctf-resource-search > div.bottom > span {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.ctf-resource-search > div.bottom > span:hover {
  color: #266cff;
}

.ctf-resource-search > div > div {
  display: inline-block;
  vertical-align: top;
}

.ctf-resource-search > div > div.search-btn {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: #30d7c0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  cursor: pointer;
}

.ctf-resource-search > div > div.statistics {
  width: calc(100% - 80px);
}

.ctf-resource-search > div > div.statistics > div {
  text-align: center;
  display: inline-block;
  width: 33.3%;
  padding: 5px 0;
}

.ctf-resource-search > div > div.w-20-div > div {
  width: 20%;
}

.ctf-resource-search > div > div.statistics > div > span {
  display: inline-block;
  width: 100%;
}

.ctf-resource-search > div > div.statistics > div > span:nth-child(1) {
  font-weight: bold;
  font-size: 25px;
}

.ctf-resource-search > div > div.statistics > div > span:nth-child(2) {
  font-size: 14px;
  color: #999;
}

.ctf-resource-table {
  margin-top: 100px;
}

.ctf-resource-table > .handle {
  margin-bottom: 10px;
  height: 32px;
}

.ctf-resource-table > .handle > .right {
  float: right;
}

.ctf-resource-table > .handle > .right> button {
  margin-right: 15px;
}

.ctf-resource-table > .handle > span {
  display: inline-block;
  height: 30px;
  vertical-align: top;
  line-height: 30px;
  padding: 0 15px;
  color: #fc7057;
  border: 1px solid #fc7057;
  background-color: rgba(252, 112, 87, 0.1);
  cursor: pointer;
}

.ctf-resource-table > .handle > button {
  float: right;
}

.table-keyword > .keyword {
  display: inline-block;
  border: 1px solid #30d7c0;
  color: #30d7c0;
  padding: 0 10px;
  background-color: rgba(48, 215, 192, 0.1);
  margin: 5px 0;
  margin-right: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.vuln-resource-list > .banner {
  background-image: url("../images/vuln-banner.png");
}

.exp-resource-list > .banner {
  background-image: url("../images/exp-banner.png");
}

.tool-resource-list {
  padding-bottom: 100px;
}

.tool-resource-list > .banner {
  background-image: url("../images/tool-banner.png");
}

.test-resource-list > .banner {
  background-image: url("../images/test-banner.png");
}
.awd-resource-list > .banner {
  background-image: url("../images/awd-banner.png");
}
.knowledge-resource-list>.banner{
  background-image: url("../images/knowledge-banner.png");
}
.recruit-resource-list>.banner{
  background-image: url('../images/recruit-banner.png');
}
.import-modal .ant-modal-body .left-text {
  line-height: 32px;
}

.import-modal .ant-modal-body .left-text b {
  color: red;
  margin-right: 2px;
}

.import-modal .line {
  margin-bottom: 12px;
}
