    .platform-activity-home>.banner {
        background: url('../images/home-banner.png') no-repeat;
        height: 300px;
        width: 100%;
        background-size: auto 100%;
        background-position: center;
    }
    
    .platform-activity-home {
        padding-bottom: 30px 0;
    }
    
    .activity-screen {
        margin-top: 30px;
    }
    
    .activity-screen>div {
        line-height: 50px;
    }
    
    .activity-screen>div>span {
        color: #666;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        width: 80px;
    }
    
    .activity-screen>div>div {
        display: inline-block;
        vertical-align: top;
        font-size: 15px;
        width: calc(100% - 80px);
    }
    
    .activity-screen>div>div>span {
        display: inline-block;
        color: #c1c1c1;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        margin-right: 10px;
        cursor: pointer;
    }
    
    .activity-screen>div>div>span.active {
        background-color: #266cff;
        color: #fff;
    }
    
    .flip-over {
        text-align: center;
        margin: 30px 0;
    }
    
    .activity-detail {
        padding-top: 105px;
        padding-bottom: 30px;
        position: relative;
    }
    
    .activity-detail>.banner {
        height: 391px;
        width: 100%;
        background: url("../images/detail-banner.png") no-repeat;
        background-size: auto 100%;
        background-position: center;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
    }
    
    .activity-detail-content {
        min-height: 500px;
        box-shadow: 0 0 5px 0 #c1c1c1;
        background-color: #fff;
        box-shadow: 0 0 10px 0 #eee;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        margin-bottom: 50px;
        position: relative;
    }
    
    .activity-detail-content>img {
        position: absolute;
        right: 0px;
        top: -3px;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
    }
    
    .current-position {
        line-height: 50px;
        padding: 0 20px;
        font-size: 14px;
    }
    
    .current-position>span {
        cursor: pointer;
    }
    
    .current-position>span:hover {
        color: #266cff;
    }
    
    .activity-detail-content>.name,
    .activity-detail-content>.time,
    .activity-detail-content>.intro,
    .activity-detail-content>.submit-matter {
        text-align: center;
    }
    
    .activity-detail-content>.name {
        font-size: 30px;
        font-weight: bold;
        line-height: 50px;
        padding: 0 30px;
    }
    
    .activity-detail-content>.time {
        font-size: 15px;
        line-height: 50px;
        font-weight: bold;
    }
    
    .activity-detail-content>.intro {
        padding: 0 50px;
        line-height: 20px;
        margin-bottom: 20px;
    }
    
    .activity-detail-content>.submit-matter {
        margin: 20px 0;
    }
    
    .activity-detail-content>.submit-matter>button {
        background-color: #30d7c0;
        color: #fff;
        cursor: pointer;
        font-size: 15px;
        border: 1px solid #30d7c0;
        padding: 5px 25px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    
    .activity-detail-content>.switch-label {
        background-color: #F7F8FA;
        text-align: center;
        border: 1px solid #DDDDDD;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
    }
    
    .activity-detail-content>.switch-label>span {
        display: inline-block;
        width: 33%;
        font-size: 16px;
        cursor: pointer;
    }
    
    .activity-detail-content>.switch-label>span.active {
        color: #266cff;
    }
    
    .activity-detail-content>.switch-label>b {
        display: inline-block;
        width: 2px;
        height: 25px;
        background-color: #898989;
        vertical-align: middle;
        margin-top: -2px;
    }
    
    .activity-my-submit,
    .activity-detail-content>.detail-show>.introduce {
        padding: 20px 40px;
    }
    
    .activity-my-submit>.table-header {
        line-height: 40px;
        color: #666;
    }
    
    .activity-my-submit>.table-header>span:nth-child(1) {
        color: #000;
        font-weight: bold;
    }
    
    .activity-my-submit>.table-header>.search {
        float: right;
    }
    
    .audit-status>span {
        display: inline-block;
        padding: 0 10px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
    }
    
    .audit-status>span.pass {
        color: #52C41A;
        border: 1px solid #52C41A;
        background: rgba(82, 196, 26, .1);
    }
    
    .audit-status>span.added {
        color: #266cff;
        border: 1px solid #266cff;
        padding: 0 15px;
        background: rgba(38, 108, 255, .1);
    }
    
    .audit-status>span.denied {
        color: #FC7057;
        border: 1px solid #FC7057;
        background: rgba(252, 112, 87, .1);
    }
    
    .table-operation>b {
        display: inline-block;
        height: 20px;
        vertical-align: middle;
        margin-top: 0px;
        width: 1px;
        background-color: #eee;
    }
    
    .activity-ranking {
        padding-bottom: 10px;
    }
    
    .activity-ranking-heder {
        line-height: 130px;
        height: 130px;
        border-bottom: 10px solid #EEEEEE;
        box-sizing: border-box;
    }
    
    .activity-ranking-heder>div {
        display: inline-block;
        width: 18%;
        text-align: center;
    }
    
    .activity-ranking-heder>div:nth-child(1) {
        vertical-align: middle;
        margin-top: -28px;
    }
    
    .activity-ranking-heder>div:nth-child(2),
    .activity-ranking-heder>div:nth-child(3),
    .activity-ranking-heder>div:nth-child(4) {
        font-size: 20px;
        font-weight: bold;
    }
    
    .activity-ranking-heder>div:nth-child(5) {
        font-size: 16px;
        color: #999;
    }
    
    .activity-ranking-table {
        width: 100%;
        text-align: center;
        table-layout: fixed;
    }
    
    .activity-ranking-table>tbody>tr {
        border-bottom: 1px solid #eee;
        line-height: 60px;
        color: #666;
        font-size: 15px;
    }
    
    .activity-ranking-table>tbody>tr>td {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .activity-ranking-table>tbody>tr:hover {
        background-color: rgba(38, 108, 255, .1);
    }
    
    .activity-ranking-table>tbody>tr>.gold {
        color: #FA891B;
        font-size: 17px;
    }
    
    .activity-ranking-table>tbody>tr>td>span {
        position: relative;
    }
    
    .activity-ranking-table>tbody>tr>td>span>b {
        position: absolute;
        width: 100%;
        display: inline-block;
        text-align: center;
        left: 2%;
        top: -118%;
        color: #fff;
    }
    
    .activity-ranking-table>tbody>tr>td>img {
        width: 45px;
        max-height: 50px;
    }
    
    .activity-ranking-table-header {
        background: linear-gradient(to bottom, #4983ED 0%, #4755E8 100%);
        color: #fff;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        margin-top: 30px;
    }
    
    .activity-ranking-table-header>span {
        display: inline-block;
        width: 16.66%;
        text-align: center;
    }