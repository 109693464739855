.submit-ctf {
    background-color: #f4f4f4;
}

.submit-ctf>.banner {
    height: 60px;
    background-color: #000;
}

.submit-ctf-div {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.submit-ctf-div>.title {
    line-height: 50px;
    height: 50px;
    padding: 0 30px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.submit-ctf-div>.title>.right {
    float: right;
    font-size: 15px;
    color: #bbb;
    font-weight: 400;
}

.submit-ctf-div>.title>.right>span {
    margin-left: 20px;
}

.submit-ctf-div>.title>span {
    display: inline-block;
    font-size: 13px;
    color: #fc7057;
    margin-left: 20px;
}

.submit-ctf-div>.content>.tags0 {
    margin-left: 80px;
}

.submit-ctf-name {
    margin-top: 20px;
    padding: 0 30px;
    line-height: 60px;
    height: 60px;
    font-size: 17px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submit-ctf-name>.ctf-name {
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.submit-ctf-name>.status {
    position: absolute;
    top: 0px;
    right: 20px;
}

.submit-ctf-name>.status>img {
    width: 75px;
}

.submit-ctf-name>.status>.logo {
    font-size: 15px;
    transform: rotate(-15deg);
    height: 30px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-ctf-name>.status>.wait {
    color: #6391e5;
    border: 3px solid #6391e5;
}

.submit-ctf-name>.status>.handle {
    color: #12b854;
    border: 3px solid #12b854;
}

.submit-ctf-name>.status>.del {
    color: rgb(252 112 87);
    border: 3px solid rgb(252 112 87);
}

.submit-ctf-name>div>span {
    cursor: pointer;
}

.submit-ctf-name>div>span:hover {
    color: #266cff;
}

.submit-ctf-name>button {
    float: right;
    margin-top: 15px;
}

.subject-info>.content {
    padding: 0 50px;
    padding-bottom: 50px;
}

.subject-info>.content>div {
    margin-top: 30px;
    position: relative;
    line-height: 40px;
}

.subject-info>.content>div>.test-level {
    display: inline-block;
}

.audit-opinion>.content {
    padding: 30px 60px !important;
}

.audit-opinion>.content>div {
    border: 1px solid #f4f4f4;
    padding: 10px 20px;
    line-height: 30px !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.audit-opinion>.content>div>div>.time {
    color: #266cff;
    line-height: 30px;
}

.activity-ctf-detail .audit-opinion>.content>div>div>.details {
    color: #333;
    line-height: 20px;
    word-break: break-all;
}

.subject-info>.content>div>span:nth-child(1),
.subject-info .content .name {
    display: inline-block;
    font-size: 15px;
    color: #bbb;
    width: 80px;
    vertical-align: top;
    line-height: 40px;
    text-align: right;
    padding-right: 5px;
}

.subject-info>.content>div>span>b,
.subject-info .content .name>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 5px;
}

.subject-info>.content>div .content-input {
    width: calc(100% - 80px);
    min-height: 40px;
    padding-right: 50px;
    line-height: 33px;
    background-color: #f4f4f4;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.subject-info>.content>div.retrieval-div>.content-input {
    width: calc(100% - 160px);
}

.subject-info>.content>div.retrieval-div>button {
    margin-left: 14px;
}

.subject-info>.content>div.retrieval-div>span.number {
    right: 90px;
}

.subject-info>.content .retrieval-div .width-select .ant-select-selector .ant-select-selection-item {
    width: 160px;
}

.subject-info>.content .retrieval-div .width-select-100 .ant-select-selector .ant-select-selection-item {
    width: 100px;
}

/* 提交知识 */
.subject-info>.content>div.retrieval-div>.tags-input {
    width: 400px;
}

.subject-info>.content>div.retrieval-div>span.tags-number {
    left: 455px;
}

.subject-info>.content>div.retrieval-div>.prompt-words {
    left: 515px;
}

.subject-info>.content>.ant-tag {
    margin-top: 20px;
    min-width: 50px;
    padding: 3px 10px;
    background-color: #f4f4f4;
    border: 0;
    color: #999;
    font-size: 14px;
}

.subject-info>.content>.ant-tag:first-child {
    margin-left: 80px;
}

/* 提交知识end */

/* 提交企业*/
.subject-info>.content>div.retrieval-div>.avatar-limit {
    color: #999;
    position: absolute;
    top: 30px;
    /* margin-top: -50px; */
}

.recruit-resource-list>.ctf-resource-table>.ant-tabs {
    overflow: visible;
    padding-bottom: 25px;
}

.activity-ctf-detail .submit-ctf-div .enterprise-detail {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    border-bottom: 1px solid #eee;

}

.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-title {
    color: #000;
    /* font-size: 15px; */
    font-size: 20px;
    font-weight: 700;
}


.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-title>.name,
.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-title>span:nth-child(2) {
    display: inline-block;
    max-width: 100px;
    height: 1.7em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 40px;
}

.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-title>.name {
    max-width: 300px;
}

.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-title>.time {
    margin-left: 20px;
    font-size: 15px;
    font-weight: 400;
}

.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-content {
    margin: 10px 0;
    max-width: 800px;
    overflow: hidden;
    word-break: normal;
    word-wrap: break-word;
    font-weight: 700;
    font-size: 15px;
}

.activity-ctf-detail .submit-ctf-div .enterprise-detail .etp-content>.treatment {
    margin-right: 20px;
    color: red;
}

.activity-ctf-detail .submit-ctf-div .enterprise-detail>.etp-info {
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    margin: auto 0;
    color: #8063EF;
}

/* 提交企业end */
.subject-info>.content>div>textArea.content-input {
    height: 80px;
    line-height: 25px;
}

.subject-info>.content>div>span.number {
    position: absolute;
    right: 12px;
    bottom: 0px;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #999;
}

.subject-info>.content>div>.class-desc {
    padding-left: 80px;
    line-height: 30px;
    color: #666;
    font-size: 12;
}

.subject-info>.content>div>.avatar-uploader {
    width: 120px;
}

.subject-info>.content>div>.element-photo {
    display: inline-block;
    vertical-align: top;
    line-height: 100px;
    color: #999;
}

.subject-info>.content>div>.content-input:focus {
    background-color: #fff;
}

.submit-ctf .submit-btn {
    padding: 30px 0 50px 0;
}

.submit-ctf .submit-btn>button {
    width: 12%;
    height: 40px;
    margin-right: 15px;
    font-size: 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.submit-ctf .submit-btn>button.modify {
    height: 30px;
    width: auto;
    font-size: 12px;
    color: #266cff;
    border: 1px solid #266cff;
    background-color: rgba(38, 108, 255, 0.1);
}

.submit-ctf .submit-btn>button.decline {
    height: 30px;
    width: auto;
    font-size: 12px;
    color: #fc7057;
    border: 1px solid #fc7057;
    background-color: rgba(252, 112, 87, 0.1);
}

.submit-ctf .submit-btn>button.added {
    height: 30px;
    width: auto;
    font-size: 12px;
    color: #30d7c0;
    border: 1px solid #30d7c0;
    background-color: rgba(48, 215, 192, 0.1);
}

.subject-keyword>.content {
    padding: 40px 50px;
}

.subject-keyword>.content>div:nth-child(1) {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    color: #bbb;
    width: 80px;
    line-height: 40px;
}

.subject-keyword>.content>div:nth-child(1)>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 20px;
}

.subject-keyword>.content>div:nth-child(2) {
    display: inline-block;
    width: calc(100% - 80px);
    vertical-align: top;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(1) {
    color: #bbb;
    font-size: 15px;
    position: relative;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(1)>input {
    height: 40px;
    width: 30%;
    background-color: #f4f4f4;
    padding-right: 40px;
    margin-right: 25px;
    line-height: 33px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(1)>input:focus {
    background-color: #fff;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(1)>span {
    position: absolute;
    left: 27%;
    top: 9px;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(2) {
    margin-top: 15px;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(2)>span {
    display: inline-block;
    color: #999;
    margin-right: 10px;
    background-color: #f4f4f4;
    padding: 3px 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.subject-keyword>.content>div:nth-child(2)>div:nth-child(2)>span>span {
    font-size: 15px;
    margin-left: 5px;
    vertical-align: top;
    margin-top: 2px;
    cursor: pointer;
}

.subject-upload .content {
    padding: 30px 50px;
}

.subject-upload .content>div>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 1px;
    margin-right: 5px;
}

.subject-upload .content>div {
    line-height: 50px;
    min-height: 50px;
}

.subject-upload .content>div>span {
    display: inline-block;
    vertical-align: top;
    width: 95px;
    text-align: right;
    color: #bbb;
    font-size: 15px;
}

.subject-upload .content>div.env-explain {
    position: relative;
}

.subject-upload .content>div.env-explain>input {
    width: calc(100% - 105px);
    margin-left: 10px;
}

.subject-upload .content>div.env-explain>span.number {
    position: absolute;
    right: 12px;
    bottom: 0px;
    color: #999;
}

.subject-upload .content>div>form {
    display: inline-block;
    margin-left: 60px;
    color: #bbb;
    font-size: 14px;
}

.subject-upload .content>div>form>div>.upload {
    color: #266cff;
    display: inline-block;
    margin-right: 10px;
    font-size: 15px;
    cursor: pointer;
}

.subject-upload .content>div>form>div>.file-name {
    color: #333;
    margin-left: 30px;
}

.subject-upload .content>div>form>div>.file-name>span {
    margin-right: 5px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.subject-upload .content>div>form>div>.upload>input {
    display: none;
}

.subject-upload .content>div>form>div>.ant-progress-line {
    display: inline-block;
    width: 200px;
}

.my-component>div {
    border: 1px solid #eee;
}

.my-component>.container {
    padding: 20px 30px;
}

.activity-ctf-detail {
    padding-bottom: 50px;
    min-height: calc(100vh - 40px);
}

.activity-ctf-detail .submit-ctf-div {
    position: relative;
}

.activity-ctf-detail .submit-ctf-div>.award {
    position: absolute;
    top: 30px;
    right: 40px;
}

.activity-ctf-detail .submit-ctf-div>.award>div>span:nth-child(1) {
    color: #fc7057;
    font-size: 80px;
    display: inline-block;
    height: 70px;
    line-height: 70px;
}

.activity-ctf-detail .submit-ctf-div>.award>p {
    color: #fc7057;
    cursor: pointer;
}

.activity-ctf-detail .submit-ctf-div>.title {
    border: 0;
}

.submit-ctf-div>.title .template {
    float: right;
}

.activity-ctf-detail .submit-ctf-div>.title>a {
    float: right;
    width: 60px;
    height: 30px;
    color: #fff;
    background-color: #266cff;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    font-weight: normal;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.activity-ctf-detail .submit-ctf-div>.title>span {
    display: inline-block;
    cursor: pointer;
}

.activity-ctf-detail .submit-ctf-div .content {
    padding: 0 45px;
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
}

.activity-ctf-detail .submit-ctf-div .content>div {
    line-height: 40px;
    color: #bbb;
    font-size: 15px;
}

.activity-ctf-detail .submit-ctf-div .content>div>span,
.activity-ctf-detail .submit-ctf-div .content>div>div,
.activity-ctf-detail .submit-ctf-div .content>div .name,
.activity-ctf-detail .submit-ctf-div .content>div .detail {
    display: inline-block;
    vertical-align: top;
    color: #bbb;
}

.activity-ctf-detail .submit-ctf-div .content>div>span,
.activity-ctf-detail .submit-ctf-div .content>div .name {
    width: 100px;
    text-align: right;
}

.activity-ctf-detail .submit-ctf-div .content>div>div,
.activity-ctf-detail .submit-ctf-div .content>div .detail {
    width: calc(100% - 240px);
    color: #666;
    margin-left: 20px;
    word-break: break-all;
}

.activity-ctf-detail .submit-ctf-div .content>div>div.ant-col {
    margin-left: 0;
}

.activity-ctf-detail .submit-ctf-div .content>div>div.ant-col .detail {
    width: calc(100% - 120px);
}

.activity-ctf-detail .submit-ctf-div .content>div>.tag>span {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    font-size: 13px;
    color: #30d7c0;
    border: 1px solid #30d7c0;
    background-color: rgba(48, 215, 192, 0.1);
    margin-right: 10px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.activity-ctf-detail .submit-ctf-div .content>div>.grade>img {
    margin-right: 5px;
    vertical-align: middle;
}

.activity-ctf-detail .buy-button {
    padding: 10px 0;
}

.activity-ctf-detail .buy-button>button {
    width: 100px;
}

/* 检索样式 */

.retrieval-box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.retrieval-box>.content {
    width: 500px;
    min-height: 200px;
    background-color: #fff;
    padding-bottom: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.retrieval-box>.content>.title {
    line-height: 40px;
    font-size: 16px;
    color: #666;
    padding: 0 20px;
    border-bottom: 1px solid #f4f4f4;
}

.retrieval-box>.content>.title>span {
    float: right;
    margin-top: 10px;
    cursor: pointer;
    font-size: 18px;
}

.retrieval-box>.content>.retrieval-search {
    padding: 0 30px;
    padding-top: 40px;
    text-align: center;
}

.retrieval-box>.content>.retrieval-search>input {
    width: 70%;
    margin-right: 20px;
}

.retrieval-box>.content>.retrieval-search>p {
    line-height: 30px;
    color: #fc7057;
    text-align: center;
    font-size: 20px;
}

.retrieval-box>.content>.retrieval-search>p>b {
    font-size: 18px;
    padding: 0 10px;
}

/* 购买样式 */

.buy-ctf-detail {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.buy-ctf-detail>.content {
    width: 350px;
    min-height: 150px;
    text-align: center;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.buy-ctf-detail>.content>img {
    position: absolute;
    top: -98px;
    left: 25px;
}

.buy-ctf-detail>.content>.cost {
    margin-top: 60px;
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
}

.buy-ctf-detail>.content>.cost>span {
    display: inline-block;
    font-size: 25px;
    margin: 0 5px;
    color: #fc7057;
}

.buy-ctf-detail>.content>.btn {
    margin-top: 20px;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #f4f4f4;
}

.buy-ctf-detail>.content>.btn>span {
    display: inline-block;
    width: 50%;
    font-size: 17px;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: bold;
    color: #999;
}

.buy-ctf-detail>.content>.btn>span:nth-child(2) {
    color: #266cff;
    border-left: 1px solid #f4f4f4;
}

.topic-answer>div {
    padding: 20px 50px;
}

.topic-answer>div>span,
.topic-answer>div>div {
    display: inline-block;
    vertical-align: top;
}

.topic-answer>div>span {
    display: inline-block;
    font-size: 15px;
    color: #bbb;
    width: 80px;
    vertical-align: top;
    line-height: 40px;
}

.topic-answer>div>span>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 5px;
}

.topic-answer>div>div {
    width: calc(100% - 80px);
}

.experimental-subject>.title>div {
    display: inline-block;
    font-size: 15px;
}

.experimental-subject>.title>span {
    color: #999;
    font-size: 15px;
    box-sizing: border-box;
    height: 100%;
    border-bottom: 3px solid transparent;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
}

.experimental-subject>.title>span>span {
    display: inline-block;
}

.experimental-subject>.title>span:hover img {
    display: block;
}

.experimental-subject>.title>span>img {
    display: none;
    width: 15px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: -15px;
}

.experimental-subject>.title>span.active {
    border-color: #266cff;
    color: #333;
}

.experimental-subject>.title>div>span {
    color: #fc7057;
}

.experimental-subject>.title>div>span:nth-child(1) {
    display: inline-block;
    color: #266cff;
    margin-right: 20px;
    cursor: pointer;
}

.experimental-subject>.content {
    padding: 20px 60px 20px 80px;
}

.experimental-subject>.content>div>span,
.experimental-subject>.content>div>div {
    display: inline-block;
    vertical-align: top;
}

.experimental-subject>.content>div {
    margin-bottom: 10px;
}

.experimental-subject>.content>div>span {
    width: 70px;
    color: #999;
    font-size: 15px;
    line-height: 40px;
}

.experimental-subject>.content>div>.flagtest-title {
    width: 100px;
}

.experimental-subject>.content>div>span>b {
    color: #fc7057;
    display: inline-block;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 10px;
}

.experimental-subject>.content>div>div {
    width: calc(100% - 70px);
    line-height: 40px;
}

.experimental-subject>.content>div>.flagtest-content {
    width: calc(100% - 100px);
}

.experimental-subject>.content>div>div>input,
.experimental-subject>.content>div>div>div>input {
    background-color: #f4f4f4;
    height: 40px;
}

.experimental-subject>.content>div>div>input:focus,
.experimental-subject>.content>div>div>div>input:focus {
    background-color: #fff;
}

.experimental-subject>.content>div>.option>div {
    margin-bottom: 10px;
}

.experimental-subject>.content>div>.option>div>input {
    width: calc(100% - 120px);
}

.experimental-subject>.content>div>.option>div>img {
    vertical-align: middle;
    margin-right: 10px;
    width: 17px;
    cursor: pointer;
}

.experimental-subject>.content>div>.option>div>span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
    color: #999;
    font-size: 20px;
}

.experimental-subject>.content>div>.option>div>span.active {
    color: #266cff;
}

.experimental-subject>.content>div>.option>div.add-btn>span {
    color: #266cff;
    font-size: 15px;
}

.test-yourself>.flag>div>.title {
    display: inline-block;
    width: 70px;
    font-size: 13px;
    font-weight: 700;
    margin-left: 20px;
}

.test-yourself>.title {
    font-size: 15px;
    font-weight: bold;
}

.test-yourself>.option {
    padding: 0 20px;
    line-height: 20px;
}

.test-yourself>.option>div {
    margin-bottom: 10px;
}

.test-yourself>.option>div>span,
.test-yourself>.option>div>div {
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
}

.test-yourself>.option>div>div {
    width: calc(100% - 150px);
}

.test-yourself>.option>div>span {
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    margin-top: 3px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #266cff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.test-yourself>.option>div>span>span {
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: #266cff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.exp-flag {
    padding-bottom: 50px;
}

.exp-flag .single-flag-show {
    padding: 0 50px;
}

.exp-flag .single-flag-show>span {
    display: inline-block;
    line-height: 40px;
    vertical-align: top;
    width: 80px;
    text-align: right;
    color: #bbb;
}

.exp-flag .single-flag-show {
    margin-top: 30px;
}

.exp-flag .single-flag-show>span>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 5px;
}

.exp-flag .single-flag-show>div {
    display: inline-block;
    width: calc(100% - 80px);
    vertical-align: top;
    line-height: 40px;
    padding-left: 15px;
}

.exp-flag .single-flag-show>div>input {
    min-height: 40px;
    padding-right: 50px;
    line-height: 33px;
    background-color: #f4f4f4;
    border-radius: 5px;
}

.exp-flag .single-flag-show>div>textarea {
    padding-right: 50px;
    line-height: 33px;
    background-color: #f4f4f4;
    border-radius: 5px;
    height: 80px;
    line-height: 25px;
}

.exp-flag .single-flag-show>div>input:focus,
.exp-flag .single-flag-show>div>textarea:focus {
    background-color: #fff;
}

.exp-flag .flag-list>.title {
    margin: 0 50px;
    border-bottom: 1px solid #ddd;
    line-height: 40px;
}

.exp-flag .flag-list>.title>div {
    display: inline-block;
    color: #999;
    font-size: 15px;
    box-sizing: border-box;
    height: 100%;
    border-bottom: 3px solid transparent;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
    padding: 0 10px;
}

.exp-flag .flag-list>.title>div.active {
    border-color: #266cff;
    color: #333;
}

.exp-flag .flag-list>.title>div>img {
    display: none;
    width: 15px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: -15px;
}

.exp-flag .flag-list>.title>div>span {
    display: inline-block;
}

.exp-flag .flag-list>.title>div:hover img {
    display: block;
}

.exp-flag .flag-list>.title>span {
    display: inline-block;
    color: #266cff;
    margin-right: 20px;
    cursor: pointer;
}

.flag-list-detail>.title {
    font-size: 15px;
    color: #333;
    font-weight: bold;
}

.flag-list-detail>.single-show {
    margin-left: 0px !important;
}

.flag-list-detail>.single-show>div>span,
.flag-list-detail>.single-show>div>div {
    display: inline-block;
    vertical-align: top;
}

.flag-list-detail>.single-show>div>span {
    width: 100px;
    text-align: right;
    color: #bbb;
}

.flag-list-detail>.single-show>div>div {
    margin-left: 20px;
    width: calc(100% - 120px);
}

.associated-number {
    display: inline-block;
    width: calc(100% - 100px);
    vertical-align: top;
}

.associated-number>.number {
    margin-bottom: 15px;
}

.associated-number>.number>input {
    min-height: 40px;
    padding-right: 50px;
    line-height: 33px;
    background-color: #f4f4f4;
    border-radius: 5px;
}

.associated-number>.number>input:focus {
    background-color: #fff;
}

.associated-number>.number>input.number-input {
    width: 250px;
}

.associated-number>.number>b {
    color: #ddd;
    margin: 0 20px;
}

.associated-number>.number>input.link-input {
    width: 500px;
}

.associated-number>.number>img {
    cursor: pointer;
    margin-left: 20px;
    width: 20px;
}

.associated-number>.tips {
    color: #999;
}

.associated-number>.tips>span {
    cursor: pointer;
    color: #266cff;
    margin: 0 5px;
}

.flag-script-box {
    width: 690px;
    min-height: 300px;
    background-color: #fff;
}

.flag-script-box .script-title {
    padding: 0 20px;
    line-height: 40px;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
}

.flag-script-box .script-title img {
    float: right;
    margin-top: 10px;
    cursor: pointer;
}

.flag-script-box .script-content {
    padding: 15px 20px;
    font-size: 14px;
    line-height: 25px;
}

.flag-script-box .script-content b {
    font-size: 15px;
}

.flag-tip-icon {
    vertical-align: top;
    margin-left: 10px;
    cursor: pointer;
}

.platform-pop-up {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.subject-info>.content>div>.test-level>img {
    margin-right: 5px;
    cursor: pointer;
    vertical-align: middle;
    margin-top: -2px;
}

.tool-book-div {
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: top;
}

.subject-upload-tool .content>div>span:nth-child(1)>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 20px;
}

.subject-upload-tool .content .upload-tool-div .upload-tool-left {
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    color: #bbb;
    width: 95px;
    line-height: 40px;
}

.subject-upload-tool .content .upload-tool-div .upload-tool-left>b {
    display: inline-block;
    color: #fc7057;
    font-size: 18px;
    vertical-align: middle;
    margin-top: 2px;
    margin-right: 20px;
}

.subject-upload-tool>.content {
    padding: 30px 50px;
    display: flex;
}

.subject-upload-tool>.content>.ant-progress {
    width: 200px;
    margin-left: 20px;
}

.subject-upload-tool .upload-tool-opt {
    display: inline-block;
    width: calc(100% - 120px);
}

.subject-test-div .retrieval-div .option {
    width: calc(100% - 80px);
    line-height: 40px;
    display: inline-block;
}

.subject-test-div>.content>div>div>input,
.subject-test-div>.content>div>div>div>input {
    background-color: #f4f4f4;
    height: 40px;
}

.subject-test-div>.content>div>div>input:focus,
.subject-test-div>.content>div>div>div>input:focus {
    background-color: #fff;
}

.subject-test-div>.content>div>.option>div {
    margin-bottom: 10px;
}

.subject-test-div>.content>div>.option>div>input {
    width: calc(100% - 160px);
}

.subject-test-div>.content>div>.option>div>img {
    vertical-align: middle;
    margin-right: 10px;
    width: 17px;
    cursor: pointer;
}

.subject-test-div>.content>div>.option>div>span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
    color: #999;
    font-size: 20px;
}

.subject-test-div>.content>div>.option>div>span.active {
    color: #266cff;
}

.subject-test-div>.content>div>.option>div.add-btn>span {
    color: #266cff;
    font-size: 15px;
}

.activity-ctf-detail .submit-ctf-div .content .option-style {
    padding-left: 30px;
}

.activity-ctf-detail .submit-ctf-div .content .option-answer-style {
    padding-left: 30px;
    position: relative;
}

.activity-ctf-detail .submit-ctf-div .content .option-answer-style::before {
    content: "";
    width: 8px;
    height: 16px;
    border-color: #4f84fc;
    border-style: solid;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 9px;
}

.submit-ctf-div .methods-scene-tags>div {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 80px);
    color: #999;
}

.submit-ctf-div .methods-scene-tags>div>span {
    color: #266cff;
    cursor: pointer;
    margin: 0 5px;
}

.submit-ctf-div .menthod-tag>.reminder {
    display: inline-block;
    font-size: 12px;
    color: #FFA777;
    margin-left: 50px;
}

.choice-method-scene {
    width: 1200px;
    min-height: 300px;
    background-color: #fff;
    border-radius: 5px;
    color: #333;
    box-shadow: 0px 0px 10px #888888;
    ;
}

.choice-method-scene>.header {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
}

.choice-method-scene>.content-left,
.choice-method-scene>.content-right {
    display: inline-block;
    width: 50%;
    padding: 10px 15px;
    vertical-align: top;
    box-sizing: border-box;
    min-height: 200px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.choice-method-scene>.content-left {
    border-right: 10px solid #E2E2E2;
}

.choice-method-scene>.bottom {
    padding: 15px 0;
    text-align: center;
    border-top: 1px solid #ddd;
}

.choice-method-scene>.bottom>button {
    margin: 0 20px;
    width: 100px;
    border-radius: 3px;
}

.choice-method-scene>.bottom>.cancel {
    background-color: #EAEAEA;
    border-color: #EAEAEA;
    color: #333;
}

.choice-method-scene>.content-left>.select-tags>span {
    display: inline-block;
}

.choice-method-scene>.content-right>div {
    line-height: 30px;
}

.choice-method-scene>.content-right>div>span {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    font-weight: bold;
}

.choice-method-scene>.content-left>.search-operate {
    margin: 10px 0;
}

/* .choice-method-scene>.content-right>div{
	font-size: 16px;
} */
.related-vulnerability {
    margin-left: 20px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 5px;
}

.related-vulnerability .related-title {
    padding: 0 10px;
    line-height: 50px;
    font-size: 15px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.related-vulnerability .related-content {
    padding: 10px;
}

.related-vulnerability .related-content .vuln-name {
    width: 100%;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.related-vulnerability .related-content .vuln-name:hover {
    color: #266cff;
}

.related-vulnerability .related-content .vuln-time {
    color: #999;
    font-size: 12px;
}

.related-vulnerability .related-content .vuln-time .right {
    float: right;
}