.audit-bounced {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.audit-bounced > .pass-box {
  width: 293px;
  min-height: 413px;
  background: url("../images/pass-box.png") no-repeat;
}

.audit-bounced > .content > .header {
  text-align: right;
  line-height: 30px;
  padding-right: 10px;
}

.audit-bounced > .content > .header > img {
  cursor: pointer;
}

.audit-bounced > .content > .detail {
  text-align: center;
  padding: 0 20px;
  margin-top: 175px;
}

.audit-bounced > .content > .detail > div:nth-child(1) {
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
}

.audit-bounced > .content > .detail > div:nth-child(2) {
  font-size: 16px;
}

.audit-bounced > .content > .detail > div:nth-child(3) {
  margin: 15px 0;
  margin-top: 30px;
}

.audit-bounced > .content > .detail > div:nth-child(3) > b {
  color: #fc7057;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  margin-top: 0px;
  font-size: 18px;
}

.audit-bounced > .content > .detail > div:nth-child(3) > input {
  width: calc(100% - 15px);
  background-color: #f4f4f4;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.audit-bounced > .content > .detail > div:nth-child(3) > textarea {
  width: calc(100% - 15px);
  background-color: #f4f4f4;
  border-radius: 3px;
  resize: none;
}

.audit-bounced > .content > .detail > div:nth-child(3) > input:focus,
.audit-bounced > .content > .detail > div:nth-child(3) > textarea:focus {
  background-color: #fff;
}

.audit-bounced > .content > .detail > div:nth-child(4) > button {
  background-color: #266cff;
  border: 1px solid #266cff;
  color: #fff;
  width: 70%;
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.audit-bounced > .decline-box {
  width: 293px;
  height: 497px;
  background: url("../images/decline-box.png") no-repeat;
}

.audit-bounced > .decline-box > .detail > div:nth-child(4) > button {
  background: #fc7057;
  border: 1px solid #fc7057;
}

.audit-bounced > .added-box {
  width: 293px;
  height: 512px;
  background: url("../images/added-box.png") no-repeat;
}

.audit-bounced > .added-box > .detail > div:nth-child(4) > button {
  background: #30d7c0;
  border: 1px solid #30d7c0;
}

.audit-bounced > .pass-box > .detail {
  margin-top: 180px;
  padding-bottom: 10px;
  background-color: #fff;
}

.audit-bounced > .pass-box > .detail > div > .name {
  font-size: 14px;
  line-height: 30px;
}

.audit-bounced > .pass-box > .detail > div > .input-dev > b {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px;
  color: #fc7057;
  margin-right: 5px;
}

.audit-bounced > .pass-box > .detail > div > .input-dev > input {
  width: calc(100% - 15px);
}

.audit-bounced > .pass-box > .detail > .btn > button {
  width: 80%;
  background-color: #266cff;
  padding: 10px 0;
  margin-bottom: 10px;
  cursor: pointer;
  color: #fff;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.audit-bounced > .apply-box {
  width: 1080px;
  height: 460px;
  background: url("../images/apply-box.png") no-repeat;
}

.audit-bounced > .apply-box > .title {
  text-align: right;
  padding-top: 45px;
  padding-right: 55px;
}

.audit-bounced > .apply-box > .title > img {
  cursor: pointer;
}

.audit-bounced > .apply-box > .inline-box {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.audit-bounced > .apply-box > .info {
  padding-left: 112px;
  padding-top: 60px;
}
.audit-bounced > .apply-box > .info > .line {
  position: relative;
  margin-top: 10px;
}
.audit-bounced > .apply-box > .info > .line:nth-child(2) {
  position: relative;
  margin-bottom: 10px;
}
.audit-bounced > .apply-box > .info > .line > div {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  color: red;
}
.audit-bounced > .apply-box > .info p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 35px;
}
.audit-bounced > .apply-box > .info > input {
  height: 30px;
}

.audit-bounced > .apply-box > .info > button {
  width: 100%;
  margin-top: 20px;
  background-color: #266cff;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.audit-bounced > .apply-box > .contact {
  text-align: center;
  margin-top: 10px;
}

.audit-bounced > .apply-box > .contact > p {
  margin-bottom: 0;
  line-height: 30px;
  color: #266cff;
}

.audit-bounced > .apply-box > .contact > img {
  width: 250px;
}

.audit-bounced > .delete-box {
  width: 300px;
  text-align: center;
  height: 150px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.audit-bounced > .delete-box > div:nth-child(1) {
  line-height: 100px;
  font-size: 20px;
  font-weight: bold;
}

.audit-bounced > .delete-box > div.btn {
  border-top: 1px solid #f4f4f4;
}

.audit-bounced > .delete-box > div.btn > span {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  width: 50%;
  font-size: 18px;
  cursor: pointer;
  color: #999;
  font-weight: bold;
}

.audit-bounced > .delete-box > div.btn > span:nth-child(1) {
  border-right: 1px solid #f4f4f4;
}

.audit-bounced > .delete-box > div.btn > span:nth-child(2) {
  color: #266cff;
}

.audit-bounced > .reminder-box {
  width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.audit-bounced > .reminder-box > p:nth-child(1) {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.audit-bounced > .reminder-box > div {
  text-align: center;
  padding: 10px 0;
}

.audit-bounced > .reminder-box > div > button {
  height: 40px;
  width: 150px;
  font-size: 16px;
}
