.platform-personal > .banner {
  height: 60px;
  background-color: #000;
}

.personal-content {
  padding: 30px 0;
}

.personal-content-left {
  width: 260px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

.personal-content-left > div {
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.personal-content-right {
  width: calc(100% - 280px);
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-block;
  vertical-align: top;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.personal-information {
  text-align: center;
  padding: 30px 0;
}

.personal-information > div:nth-child(2) {
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 20px;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.personal-information > div:nth-child(3) {
  line-height: 20px;
  font-size: 14px;
  color: #666;
  padding: 0 20px;
}

.personal-information > div:nth-child(4) {
  margin-top: 50px;
}

.personal-information > div:nth-child(4) > div {
  display: inline-block;
  width: 33%;
}

.personal-information > div:nth-child(4) > div > span,
.personal-information > div:nth-child(4) > div > b {
  display: inline-block;
  width: 100%;
  color: #999;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.personal-information > div:nth-child(4) > div > b {
  font-size: 25px;
  color: #333;
}

.personal-information > div:nth-child(4) > b {
  display: inline-block;
  height: 25px;
  vertical-align: top;
  width: 1px;
  background-color: #ddd;
  margin-top: 25px;
}

.platform-personal-nav {
  padding: 10px 0;
  margin-top: 10px;
}

.platform-personal-nav > div {
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.platform-personal-nav > div.single-nav {
  height: 50px;
  line-height: 50px;
}

.platform-personal-nav > div > span {
  float: right;
  color: #999;
  font-weight: normal;
  font-size: 13px;
}

.platform-personal-nav > div > b {
  position: absolute;
  left: 0px;
  top: 8px;
  height: 25px;
  display: inline-block;
  width: 2px;
}

.platform-personal-nav > div.single-nav > b {
  top: 13px;
}

.platform-personal-nav > div.active > b {
  background-color: #266cff;
}

.personal-message > .title {
  line-height: 70px;
  border-bottom: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: bold;
  padding: 0 30px;
}

.personal-message > .photo {
  text-align: center;
  margin: 50px 0;
}

.personal-message > .photo > div {
  display: inline-block;
  vertical-align: middle;
}

.personal-message > .photo > div:nth-child(1) {
  width: 80px;
  height: 80px;
  position: relative;
}

.personal-message > .photo > div:nth-child(1) > .bg {
  position: absolute;
  line-height: 80px;
  cursor: pointer;
  color: #fff;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}

.personal-message > .photo > div:nth-child(2) {
  line-height: 25px;
  font-size: 13px;
  color: #999;
  margin-left: 20px;
}

.personal-message > .basic-info {
  padding: 0 50px;
  padding-bottom: 150px;
}

.personal-message > .basic-info > div > span,
.personal-message > .basic-info > div > div {
  display: inline-block;
  vertical-align: top;
  line-height: 50px;
}

.personal-message > .basic-info > div > span {
  width: 70px;
  text-align: right;
  font-size: 16px;
  color: #999;
  margin-right: 20px;
}

.personal-message > .basic-info > div > div {
  width: 200px;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  color: #333;
}

.personal-message > .basic-info > div > .operate {
  text-align: left;
  color: #fc7057;
  font-size: 14px;
  cursor: pointer;
}

.personal-message > .basic-info > div > div.edit {
  width: 80%;
}

.personal-message > .basic-info > div > div.edit > input {
  width: 30%;
}

.personal-message > .basic-info > div > div.edit > button {
  margin-left: 20px;
}

.resource-submit-list > .title {
  line-height: 70px;
  border-bottom: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: bold;
  padding: 0 30px;
}

.resource-submit-list > .content {
  padding: 0 30px;
  /* padding-bottom: 50px; */
  min-height: 630px;
}
.resource-submit-list > .content > .all-count {
  line-height: 40px;
  color: #666;
  margin-bottom: 10px;
}

.resource-submit-list > .content > .all-count > span {
  width: 20%;
  float: right;
  margin-top: 8px;
}

.have-bought > .toggle {
  border-bottom: 1px solid #f4f4f4;
  height: 60px;
  line-height: 60px;
}

.have-bought > .toggle > span {
  display: inline-block;
  width: 20%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.have-bought > .toggle > span > b {
  display: inline-block;
  width: 65%;
  height: 3px;
  position: absolute;
  bottom: 0px;
  left: 42px;
}

.have-bought > .toggle > span.active > b {
  background-color: #266cff;
}
