html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    /* vertical-align: baseline; */
    background: transparent;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600!important;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

.red-star {
    position: absolute;
    font-size: 20px;
    color: #ff0101;
    top: 4px;
    left: -14px;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

.huiCar {
    cursor: not-allowed;
}


/* remember to define focus styles! */

:focus {
    outline: 0;
}


/* remember to highlight inserts somehow! */

ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}


/* tables still need 'cellspacing="0"' in the markup */

html,
body,
#root {
    width: 100%;
    height: 100%;
    color: #333;
    /* overflow-y: hidden; */
}

input::-webkit-input-placeholder,
textarea ::-webkit-textarea-placeholder {
    color: #5f6a81;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #5f6a81;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #5f6a81;
}

input:-ms-input-placeholder,
textarea:-ms-textarea-placeholder {
    /* Internet Explorer 10-11 */
    color: #5f6a81;
}

button,
input {
    outline: none;
    border: none;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
    background: #266cff;
    border-radius: 10px;
}

*::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}


/* 清除input  type=search的X */

input::-webkit-search-cancel-button {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
    color: #aaa;
    font-size: 14px;
}


/* 自己加的样式 */


/* 给富文本框设置高度 */

.w-e-text-container {
    height: 100px !important;
}

.ant-table-tbody>tr>td {
    word-wrap: break-word;
    word-break: break-all;
}